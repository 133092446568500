import "./pricing.css";
import osnovno from "../assets/osnovno.png";
import tapiceriq from "../assets/tapiceriq.jpg";
import abonametnoPoch from "../assets/abonamentno.png";
import prozorci from "../assets/prozorci.jpg";
import banq from "../assets/banq.png";
import elektro from "../assets/elektro.jpg";
import sledRemont from "../assets/osnovnoSledRemont.png";

import zamzkimateriali from "../assets/zamazkiMateriali.jpg";
import zamzazkiTrud from "../assets/ZamazkiTrud.jpg";
import plochki from "../assets/plochki.jpg";
import plochkiTrud from "../assets/plochkiTrud.jpg";
import boq from "../assets/boqTrud.jpg";
import boqSamoTrud from "../assets/boqSamotrud.jpg";

import drugi from "../assets/drugileft.png";
import complications from "../assets/complications.png";

import divider from "../assets/divider.svg";

function Pricing({}) {
  return (
    <div id="pricing-wrapper">
      <div id="pricing-img-section">
        <div id="img-head"></div>
        <img id="pricing-divider" src={divider} alt="divider" />
        <div id="img-pricing-text">
          <h2>Цени</h2>
          <h3>Цени за почистване с оптимум строй</h3>
          <a href="tel:0877137077">
            <span>
              Позвънете за оферта <i className="fa-solid fa-angle-right"></i>
            </span>
          </a>
        </div>
      </div>
      <div id="price-wrapper">
        <div id="prices">
          <div
            onClick={() => {
              const img = document
                .getElementById("1-price")
                .classList.toggle("show");
            }}
            className="price-container"
          >
            <h2>
              Основно Почистване на Домове и Офиси{" "}
              <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
            </h2>
            <div id="1-price" className="img-price">
              <img src={osnovno} alt="cena" />
            </div>
          </div>
          <div
            onClick={() => {
              const img = document
                .getElementById("8-price")
                .classList.toggle("show");
            }}
            className="price-container"
          >
            <h2>
              Основно Почистване След Ремонт{" "}
              <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
            </h2>
            <div id="8-price" className="img-price">
              <img src={sledRemont} alt="cena" />
            </div>
          </div>
          <div
            onClick={() => {
              const img = document
                .getElementById("2-price")
                .classList.toggle("show");
            }}
            className="price-container"
          >
            <h2>
              Пране на Тапицерия{" "}
              <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
            </h2>
            <div id="2-price" className="img-price">
              <img src={tapiceriq} alt="cena" />
            </div>
          </div>
          <div
            onClick={() => {
              const img = document
                .getElementById("3-price")
                .classList.toggle("show");
            }}
            className="price-container"
          >
            <h2>
              Абонаментно Почистване{" "}
              <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
            </h2>
            <div id="3-price" className="img-price">
              <img src={abonametnoPoch} alt="cena" />
            </div>
          </div>
          <div
            onClick={() => {
              const img = document
                .getElementById("4-price")
                .classList.toggle("show");
            }}
            className="price-container"
          >
            <h2>
              Миене на Прозорци и Витрини{" "}
              <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
            </h2>
            <div id="4-price" className="img-price">
              <img src={prozorci} alt="cena" />
            </div>
          </div>
          <div
            onClick={() => {
              const img = document
                .getElementById("5-price")
                .classList.toggle("show");
            }}
            className="price-container"
          >
            <h2>
              Миене на Баня{" "}
              <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
            </h2>
            <div id="5-price" className="img-price">
              <img src={banq} alt="cena" />
            </div>
          </div>
          <div
            onClick={() => {
              const img = document
                .getElementById("6-price")
                .classList.toggle("show");
            }}
            className="price-container"
          >
            <h2>
              Почистване на Домашни Електроуреди{" "}
              <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
            </h2>
            <div id="6-price" className="img-price">
              <img src={elektro} alt="cena" />
            </div>
          </div>
          <div
            onClick={() => {
              const img = document
                .getElementById("7-price")
                .classList.toggle("show");
            }}
            className="price-container"
          >
            <h2>
              Вътрешно-Ремонтни Дейности{" "}
              <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
            </h2>
            <div id="7-price" className="img-price">
              <img src={zamzazkiTrud} alt="cena" />
              <img src={zamzkimateriali} alt="cena" />
              <img src={plochki} alt="cena" />
              <img src={plochkiTrud} alt="cena" />
              <img src={boq} alt="cena" />
              <img src={boqSamoTrud} alt="cena" />
            </div>
          </div>
        </div>
        <div id="compl">
          <div>
            {" "}
            <img src={drugi} alt="cena" />
          </div>
          <div>
            <img src={complications} alt="cena" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Pricing;
