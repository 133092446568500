import { Link } from "react-router-dom";
import "./services.css";

function Services({ lang, logged, isLogged, state }) {
  return (
    <div id="services-wrapper">
      <div id="services">
        <div id="title-serv">
          <h2>{lang ? "Нашите Услуги" : "Our Services"}</h2>
        </div>
        <div id="services-cards">
          <div>
            <div
              onClick={() => {
                window.location.href = "/info?id=1";
              }}
            >
              <div id="f-serv-1-img" className="f-serv">
                <i className="out fa-solid fa-broom"></i>
              </div>
              <div className="serv-title-p">
                {" "}
                <p>Основно Почистване на Домове и Офиси</p>
              </div>
              <p className="service-info">
                Професионалните ни почистващи услуги ще ви облекчат от
                задължения и ще Ви спестят време, което може да вложите в
                развитието на бизнеса Ви. Персонализиланите ни услуги са
                съобразени с вашия бюджет, без да компенсираме от стандартите за
                чистота, осигуряват редовен, гъвкав и последователен график за
                почистване, с което подобряваме продуктивността и
                благосъстоянието на вашите служители.
              </p>
              <div className="serv-button">
                <Link>Научете поевече</Link>
              </div>
            </div>
            <div
              onClick={() => {
                window.location.href = "/info?id=2";
              }}
            >
              <div id="f-serv-2-img" className="f-serv">
                <i className="out fa-solid fa-soap"></i>
              </div>
              <div className="serv-title-p">
                <p>Пране на Тапицерия</p>
              </div>
              <p className="service-info">
                Машинно изпиране на мека мебел - дивани, кресла, столове,
                килими, пуфове, тапицирани легла, мокети и др. Всички знаем
                колко е трудно да се почистят петната върху мека мебел. На
                никому не е приятно, когато изцапаме дивана в хола с вино, кафе,
                шоколад или сок, а още повече и загубените нерви по време на
                чистенето.
              </p>
              <div className="serv-button">
                <Link>Научете поевече</Link>
              </div>
            </div>
            <div
              onClick={() => {
                window.location.href = "/info?id=3";
              }}
            >
              <div id="f-serv-3-img" className="f-serv">
                {" "}
                <i className="out fa-solid fa-calendar-days"></i>
              </div>
              <div className="serv-title-p">
                <p>Абонаментно Почистване</p>
              </div>
              <p className="service-info">
                Защо да се задоволявате с еднократно почистване, когато може да
                имате трайно и ефикасно решение? <br />
                <br />
                Абонаментът гарантира свежест седмица след седмица.
                Абонаментните ни услуги са различни и персонализирани за всеки
                наш уникален клиент.
              </p>
              <div className="serv-button">
                <Link>Научете поевече</Link>
              </div>
            </div>
          </div>
          <div>
            <div
              onClick={() => {
                window.location.href = "/info?id=4";
              }}
            >
              <div id="f-serv-4-img" className="f-serv">
                <i className="out fa-solid fa-window-restore"></i>
              </div>
              <div className="serv-title-p">
                <p>Миене на Прозорци и Витрини</p>
              </div>
              <p className="service-info">
                Чувствате ли, че дома или офиса Ви се нуждае от почистване?
                <br />
                <br />
                Подновяването на чистотата на прозорците и витрините не само ще
                направи обстановката Ви по-светла, но ще създаде и усещане за
                свежест, уют и продуктивност.
                <br />
                <br /> Незaвисимо дали търсите професионална помощ или просто не
                искате да се занимавате лично с този процес, ще се отзовем на
                помощ.
              </p>
              <div className="serv-button">
                <Link>Научете поевече</Link>
              </div>
            </div>
            <div
              onClick={() => {
                window.location.href = "/info?id=5";
              }}
            >
              <div id="f-serv-5-img" className="f-serv">
                <i className="out fa-solid fa-toilet"></i>
              </div>
              <div className="serv-title-p">
                <p>Миене на Баня</p>
              </div>
              <p className="service-info">
                Поддържането на чистота в банята е често предизвикателство, но с
                внимание и грижи може да стане по-лесно. Услугите ни за
                почистване на гарантират свежест и чистота, като се грижим за
                всеки ъгъл и детайл. Чисти фуги, блестящи плочки и санитария са
                част от нашата грижа, без значение дали изберете редовни графици
                за почистване или единично обслужване.
              </p>
              <div className="serv-button">
                <Link>Научете поевече</Link>
              </div>
            </div>
            <div
              onClick={() => {
                window.location.href = "/info?id=6";
              }}
            >
              <div id="f-serv-6-img" className="f-serv">
                <i className="out fa-solid fa-house-laptop"></i>
              </div>
              <div className="serv-title-p">
                <p>Почистване на Домашни Електроуреди</p>
              </div>
              <p className="service-info">
                Подлагаме нашите уреди на много - разливи, петна, трохи,
                засъхнала мазнина, каквото и да е. Почистването им се усеща като
                безкрайна битка. Понякога мисълта да се захванете с тази гадост
                е просто поразителна.Искате ли да имате възможността да се
                отпуснете на дивана, гледайки любимия Ви сериал или да се
                насладите на хоби, вместо да се борите с мръсотията от фурната?
                Професионалните ни почистващи услуги ви дават този лукс.
              </p>
              <div className="serv-button">
                <Link>Научете поевече</Link>
              </div>
            </div>
          </div>

          <div id="last-serv">
            <div
              onClick={() => {
                window.location.href = "/info?id=7";
              }}
            >
              <div id="f-serv-7-img" className="f-serv">
                <i className="out fa-solid fa-snowplow"></i>
              </div>
              <div className="serv-title-p">
                {" "}
                <p>
                  Основно Почистване <br /> След Ремонт
                </p>
              </div>
              <p className="service-info">
                Ремонтът почти никога не е лесен, евтин или забавен. Много често
                ремонтът остава след себе си и негативни последици – мръсотия,
                безредие и употири петна, които в повечето случаи ни
                обезкуражават и отказват от тяхното почистване. Спестете мъки и
                усилия и ни се доверете да свършим мръсната работа вместо Вас!
                Гарантираме цялостно и ефективно почистване на целия обект, без
                значение от нивото на замърсеност.
              </p>
              <div className="serv-button">
                <Link>Научете поевече</Link>
              </div>
            </div>
            <div
              onClick={() => {
                window.location.href = "/info?id=8";
              }}
            >
              <div id="f-serv-8-img" className="f-serv">
                {" "}
                <i className="out fa-solid fa-paint-roller"></i>
              </div>
              <div className="serv-title-p">
                <p>
                  Вътрешно-Ремонтни <br /> Дейности
                </p>
              </div>
              <p className="service-info">
                Когато искате домът Ви да бъде винаги в отлично състояние,
                вътрешно-ремонтните ни услуги са вашите верни съюзници. Няма
                значение дали имате нужда от поправка на капеща чешма или от
                цялостно преобразуване на кухнята Ви, тези услуги ви предлагат
                решения, които са едновременно ефективни и изгодни. Качество, на
                което можете да се доверите: Разчитайте, че професионалистите ни
                ще свършат работата безупречно и ще ви зарадват с резултатите.
              </p>
              <div className="serv-button">
                <Link>Научете поевече</Link>
              </div>
            </div>
          </div>
        </div>
        <a href="tel:0877137077">Позвънете за Оферта</a>
      </div>
    </div>
  );
}

export default Services;
