import "./slider.css";
import { useState, useRef, useEffect } from "react";
import window from "../../assets/window.jpg";
import sink from "../../assets/sink.jpg";
import master from "../../assets/master.jpg";
// import divider from "../../assets/divider.svg";
import chicoto from "../../assets/chichoto.png";
const img = [chicoto, sink, master];
const delay = 2500;
const titlesSlider = [
  { f: "Пране на ", l: "Тапицерия" },
  { f: "Основно Почистване", l: " на Домове и Офиси" },
  { f: "Абонаментно", l: "Почистване" },
];
const titlesSliderSafari = [
  { f: "Пране на ", l: "Тапицерия", s: "", g: "" },
  { f: "Основно", s: "Почистване", l: " на Домове", g: " и Офиси" },
  { f: "Абонаментно", s: "", l: "Почистване", g: "" },
];
function Slider() {
  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  console.log(isSafari);
  let widthOfWindow = document.documentElement.clientWidth;
  console.log(widthOfWindow);
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === img.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div id="slide-divider">
      <div className="slideshow">
        <div
          className="slideshowSlider"
          style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
        >
          {img.map((img, index) => {
            if (isSafari && widthOfWindow < 1000) {
              return (
                <div
                  className="slide"
                  id={`slide-${index}`}
                  key={index}
                  style={{
                    backgroundImage: `linear-gradient( to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${img})`,
                  }}
                >
                  <div>
                    <h2 className="inner-text">
                      {titlesSliderSafari[index].f} <br />{" "}
                      {titlesSliderSafari[index].s} <br />{" "}
                      {titlesSliderSafari[index].l} <br />{" "}
                      {titlesSliderSafari[index].g}
                    </h2>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  className="slide"
                  id={`slide-${index}`}
                  key={index}
                  style={{
                    backgroundImage: `linear-gradient( to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${img})`,
                  }}
                >
                  <div>
                    <h2 className="inner-text">
                      {titlesSlider[index].f} <br /> {titlesSlider[index].l}
                    </h2>
                  </div>
                </div>
              );
            }
          })}
        </div>

        <div className="slideshowDots">
          {img.map((_, idx) => (
            <div
              key={idx}
              className={`slideshowDot${index === idx ? " active" : ""}`}
              onClick={() => {
                setIndex(idx);
              }}
            ></div>
          ))}
        </div>
      </div>
      {/* <img src={divider} alt="divider" /> */}
    </div>
  );
}

export default Slider;
