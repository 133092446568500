import { Link } from "react-router-dom";
import "./header.css";
import divider from "../../assets/divider.svg";

function Header({ title, img }) {
  return (
    <>
      {" "}
      <div
        id="header-services-info"
        style={{
          backgroundImage: `linear-gradient( to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${img})`,
        }}
      >
        <div>
          <h2>{title}</h2>
          <p>
            <Link to="/services">Услуги</Link>
            <i className="fa-solid fa-circle"></i> <span>{title}</span>
          </p>
        </div>
        <img id="services-divider" src={divider} alt="loading" />
      </div>
    </>
  );
}

export default Header;
