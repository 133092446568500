import { Fragment, useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./home/home";
import Contacts from "./contacts/contacts";
import Privacy from "./privacy/privacy";
import Pricing from "./pricing/pricing";
import Services from "./sevices/services";
import ServicesInfo from "./servicesInfo/servicesInfo";

import Nav from "./nav/nav";
import Footer from "./footer/footer";
import Terms from "./terms/terms";
function App() {
  const [lang, setLang] = useState(true);
  const [newLoaded, setNewLoaded] = useState(true);

  return (
    <>
      <Fragment>
        <Nav
          newLoaded={newLoaded}
          setNewLoaded={setNewLoaded}
          lang={lang}
          setLang={setLang}
        />
      </Fragment>
      <Routes>
        <Route index exact element={<Home />} />
        <Route exact path="/home" element={<Home lang={lang} />} />
        <Route exact path="/services" element={<Services lang={lang} />} />
        <Route exact path="/pricing" element={<Pricing />} />
        <Route exact path="/contacts" element={<Contacts lang={lang} />} />
        <Route exact path="/info" element={<ServicesInfo />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/terms" element={<Terms />} />

        <Route exact path="/*" element={<>Not Found 404</>} />
      </Routes>
      <Fragment>
        <Footer lang={lang} />
      </Fragment>
    </>
  );
}

export default App;
