import "./contacts.css";
import Form from "../form/form";

function Contacts({ lang }) {
  return (
    <div id="contact-wrapper">
      <div id="contact">
        <h2>{lang ? "Свържете се с нас!" : "Our Services"}</h2>
        <div>
          <div>
            {/* <p>
              Добре дошли на нашата страница за контакти! Радваме се, че
              избирате нашите услуги. Независимо дали имате запитване за цена,
              имате нужда от повече информация или просто искате да поговорите,
              ние сме тук, за да ви помогнем.
            </p> */}
            <p></p>
            <p>
              Търсите подробна информация за цените? Или има неразбирателство в
              услугите ни?
              <br />
              <br /> Не се колебайте да оставите вашите запитвания или да се
              свържете с нас директно! Нашият екип е готов да окаже
              съдействието, от което се нуждаете. За да улесним процеса,
              предоставихме удобна форма за контакт.
              <br />
              <br />
              Просто попълнете данните си, изберете естеството на вашето
              запитване и напишете вашето съобщение. Ще ви отговорим възможно
              най-бързо.
            </p>
            <p></p>
          </div>
          <div>
            <Form />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
