import "./servicesInfo.css";
import Header from "./header/header";
import clean from "../assets/cleanprice.jpg";
import { useParams, useLocation } from "react-router-dom";
import React from "react";
import Form from "../form/form";

const pages = [
  {
    title: "Основно Почистване на Домове и Офиси",
    p: {
      part1:
        "Основното почистване на жилището Ви е важно, както за уюта и визията му, така и за вашето здраве!",
      part2:
        "Ние от „ОПТИМУМ СТРОЙ“ знаем, че на моменти ежедневието може да Ви дойде в повече. Нашите персонализирани услуги отговарят на вашия график, независимо дали сте заети с работа, деца, домакинство или просто искате да си починете. Доверете се на нас и кажете сбогом на чистенето през уикенда и отделете времето за себе си, което заслужавате!",
    },
    list: [
      "Прахосмукиране, миене и полиране на под",
      "Обезпрашване, чистене и дезинфекция на мебели",
      "Пране на дивани и мека мебел",
      "Миене на дограми, врати и прозорци",
      "Миене на домашни електроуреди",
      "Почистване и миене на бани и тераси",
      "Почистване на паяжини и осветителни тела",
      "Почистване на комарници, щори и други",
    ],
  },
  {
    title: "Пране на Тапицерия",
    p: {
      part1:
        "Машинно изпиране на мека мебел - дивани, кресла, столове, килими, пуфове, тапицирани легла, мокети и др. ",
      part2:
        "Всички знаем колко е трудно да се почистят петната върху мека мебел. На никому не е приятно, когато изцапаме дивана в хола с вино, кафе, шоколад или сок, а още повече и загубените нерви по време на чистенето. Нека ви спестим мъките и усилията с нашите еднократни или абонаментни услуги за професионало пране на тапицерия за да върнем уюта и изящния вид у дома или на работното място.",
    },
    list: [
      "Премахване на петна от мека мебел, матраци и други тапицерни мебели и др.",
      "Пране на салон на автомобили",
      "Пране на мокети и килими",
    ],
  },
  {
    title: "Абонаментно Почистване",
    p: {
      part1:
        "Защо да се задоволявате с еднократно почистване, когато може да имате трайно и ефикасно решение? Абонаментът гарантира свежест седмица след седмица. Абонаментните ни услуги са различни и персонализирани за всеки наш уникален клиент. С абонаментните ни услуги получавате отстъпки, допълнтелни услуги, като например домашен майстор и техническа поддръжка, съобразен с Вас график и получавате приоритетен достъп до услугите ни.",
      part2:
        " Предлагаме „Домашен майстор“, като допълнителна услуга към абонаментните ни почиствания. Когато се нуждаете от смяна на кранче, батерия на чешмата, други подобни дреболии или просто едно освежаване, можете да се доберете до нас.",
    },
    list: [],
  },
  {
    title: "Миене на Прозорци и Витрини",
    p: {
      part1: "Чувствате ли, че дома или офиса Ви се нуждае от почистване?",
      part2:
        "Подновяването на чистотата на прозорците и витрините не само ще направи обстановката Ви по-светла, но ще създаде и усещане за свежест, уют и продуктивност.  Незвисимо дали търсите професионална помощ или просто не искате да се занимавате лично с този процес, ще се отзовем на помощ. ",
    },
    list: [],
  },
  {
    title: "Миене на Баня",
    p: {
      part1:
        "Поддържането на чистота в банята е често предизвикателство, но с внимание и грижи може да стане по-лесно. ",
      part2:
        "Услугите ни за почистване на гарантират свежест и чистота, като се грижим за всеки ъгъл и детайл. Чисти фуги, блестящи плочки и санитария са част от нашата грижа, без значение дали изберете редовни графици за почистване или единично обслужване.",
    },
    list: [
      "Почистване и дезинфекциране на вани, мивки и тоалетни;",
      "Отстраняване на варовикови наслоявания;",
      "Почистване на мухъл;",
      "Обстойно почистване на всички фуги;",
      "Почистване и полиране на огледала;",
      "Обезмасляване и полиране на фаянс и теракот;",
      "Измиване на душ кабина;",
      "Почистване на подовата настилка.",
    ],
  },
  {
    title: "Почистване на Домашни Електроуреди",
    p: {
      part1:
        "Подлагаме нашите уреди на много - разливи, петна, трохи, засъхнала мазнина, каквото и да е. Почистването им се усеща като безкрайна битка. Понякога мисълта да се захванете с тази гадост е просто поразителна.",
      part2:
        "А и уикендите са предназначени за почивка, а не за чистене. Искате ли да имате възможността да се отпуснете на дивана, гледайки любимия Ви сериал или да се насладите на хоби, вместо да се борите с мръсотията от фурната? Професионалните ни почистващи услуги ви дават този лукс.",
    },
    list: [
      "Почистване от упорити петна и цялостно почистване на фурни, перални, хладилници, микровълнови фурни и други домашни електроуреди",
    ],
  },
  {
    title: "Основно Почистване След Ремонт",
    p: {
      part1:
        "Ремонтът почти никога не е лесен, евтин или забавен. Много често ремонтът остава след себе си и негативни последици – мръсотия, безредие и употири петна, които в повечето случаи ни обезкуражават и отказват от тяхното почистване. ",
      part2:
        "Спестете мъки и усилия и ни се доверете да свършим мръсната работа вместо Вас! Гарантираме цялостно и ефективно почистване на целия обект, без значение от нивото на замърсеност.",
    },
    list: [
      "Измиване на твърди настилки",
      "Чистене на меки настилки",
      "Изхвърляне на отпадъци",
      "Обезпрашване и дезинфекция",
      "Измиване на дограми и прозирци",
      "Измиване на домашни електроуреди и други",
    ],
  },
  {
    title: "Вътрешно-Ремонтни Дейности",
    p: {
      part1:
        "Когато искате домът Ви да бъде винаги в отлично състояние, вътрешно-ремонтните ни услуги са вашите верни съюзници. Няма значение дали имате нужда от поправка на капеща чешма или от цялостно преобразуване на кухнята Ви, тези услуги ви предлагат решения, които са едновременно ефективни и изгодни.",
      part2:
        "Качество, на което можете да се доверите: Разчитайте, че професионалистите ни ще свършат работата безупречно и ще ви зарадват с резултатите. По-добър дом за вас: Не само домът Ви ще стане по-красив, но и по-комфортен и ценен. Разполагаме с уменията и опита, за да се справим с почти всяка задача и дори ще ви дадем някои дружелюбни съвети, за да освежите мястото си.",
    },
    list: [],
  },
];
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function ServicesInfo() {
  let query = useQuery();
  let id = query.get("id");
  id = Number(id);
  id = id > 0 && id < 9 ? id : 1;
  const page = pages[id - 1];
  return (
    <>
      <Header title={page.title} img={clean} />
      <div id="main-section-service-info">
        <div id="service-info-section-1">
          <h2 id="main-title-service-info">Описание на услугата</h2>
          <p id="service-info-paragraph">
            {page.p.part1}
            <br />
            <br />
            {page.p.part2}
          </p>
          <div
            id="service-include"
            style={
              page.list.length === 0
                ? { display: "none" }
                : { display: "block" }
            }
          >
            <h2>Услугата включва:</h2>
            <ul>
              {page.list.map((l) => {
                return <li>{l}</li>;
              })}
            </ul>
          </div>
        </div>
        <div id="service-info-section-2">
          <Form />
        </div>
      </div>
    </>
  );
}
export default ServicesInfo;
