import "./home.css";
import home1 from "../assets/homeCleanBanner.jpg";
import home2 from "../assets/clean-home-floating.jpg";
import Slider from "./slider/slider";
function Home({ lang }) {
  return (
    <>
      <Slider />
      <div id="home-main-wrapper">
        <div id="home-main">
          <div id="section-home-1">
            <div id="section-1-img">
              <img id="main-img" src={home1} />
              {/* <img id="main-floating" src={home2} /> */}
            </div>
            <div id="section-1-text">
              <h2>За нас</h2>
              <h2>Защото никой не го прави по-добре!</h2>
              <p>
                Ние сме почистваща фирма, която се ангажира да Ви осигури
                възможно най-високо качество на предлаганите от нас услуги, за
                да надхвърлим очакванията Ви. Чрез висок стандарт на обслужване,
                успяваме да се утвърдим като сигурен и надежден партньор.
                Постоянно работим за повишване на доверието в нашите клиенти.
              </p>
              <ul>
                <li>
                  <i className="fa-solid fa-calendar-days"></i>Лесна и бърза
                  заявка за услуга
                </li>
                <li>
                  {" "}
                  <i className="fa-solid fa-face-smile"></i>100% гаранция за
                  качеството на услугите
                </li>
                <li>
                  <i className="fa-solid fa-leaf"></i>Използваме екологично
                  чисти препарати
                </li>
                <li>
                  <i className="fa-solid fa-tags"></i>Добри отстъпки при
                  индивидуални поръчки
                </li>
              </ul>
              <div id="section-1-text-sub">
                <button
                  onClick={() => {
                    window.location.href = "/contacts";
                  }}
                >
                  Запитване <i className="fa-solid fa-play"></i>
                </button>
                <a href="tel:0877137077">
                  <i className="fa-solid fa-phone"></i>0877137077
                </a>
              </div>
            </div>
          </div>
          <div id="section-home-2">
            <h2>Нашите Услуги</h2>
            <h2>Топ Предложения</h2>
          </div>
          <div id="section-home-3">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.location.href = "/info?id=3";
              }}
            >
              <div id="home-img-1">
                <i className="fa-solid fa-calendar-days"></i>
              </div>
              <h2>Абонаментно Почистване</h2>
              <p>
                {" "}
                Абонаментът гарантира свежест седмица след седмица.
                Абонаментните ни услуги са различни и персонализирани за всеки
                наш уникален клиент.
              </p>
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.location.href = "/info?id=4";
              }}
            >
              <div id="home-img-2">
                <i className="fa-solid fa-window-restore"></i>
              </div>
              <h2>Миене на Прозорци и Витрини</h2>
              <p>
                На никому не е приятно, когато изцапаме дивана в хола с вино,
                кафе, шоколад или сок, а още повече и загубените нерви по време
                на чистенето.
              </p>
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.location.href = "/info?id=2";
              }}
            >
              <div id="home-img-3">
                <i className="fa-solid fa-soap"></i>
              </div>
              <h2>Пране на Тапицерия</h2>
              <p>
                Подновяването на чистотата на прозорците и витрините не само ще
                направи обстановката Ви по-светла, но ще създаде и усещане за
                свежест, уют и продуктивност.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
