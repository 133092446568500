import "./nav.css";
import logo from "../assets/logo.png";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Nav({ lang, newLoaded }) {
  const navigateNav = useNavigate();
  const [page, setPage] = useState(0);
  const location = useLocation();
  document.body.addEventListener("click", (e) => {
    const desiredElement = document.getElementById("menu");
    const hamOpen = document.getElementById("ham");
    if (!desiredElement.contains(e.target) && !hamOpen.isSameNode(e.target)) {
      document.getElementById("menu").style.right = "-70%";
    } else {
    }
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (
      window.location.pathname === "/home" ||
      window.location.pathname === "/"
    ) {
      setPage(1);
    } else if (window.location.pathname === "/services") {
      setPage(2);
    } else if (window.location.pathname === "/pricing") {
      setPage(3);
    } else if (window.location.pathname === "/contacts") {
      setPage(4);
    }
  }, [location]);
  if (newLoaded) {
    if (page === 0) {
      if (
        window.location.pathname === "/home" ||
        window.location.pathname === "/"
      ) {
        setPage(1);
      } else if (window.location.pathname === "/services") {
        setPage(2);
      } else if (window.location.pathname === "/pricing") {
        setPage(3);
      } else if (window.location.pathname === "/contacts") {
        setPage(4);
      }
    } else {
      // setNewLoaded(false);
    }
  }

  return (
    <>
      <div id="gore">
        <p>БЕЗПЛАТЕН ОГЛЕД И ОФЕРТА 0878583827 / 0877137077</p>
      </div>
      <div id="nav-wrapper">
        <nav id="nav">
          <div
            onClick={() => {
              navigateNav("/home");
            }}
            id="logo"
          >
            <img alt="logo" src={logo} />
          </div>{" "}
          <div id="menu">
            <div id="ham-mneu">
              <img alt="logo" src={logo} />
              <i
                id="open-ham"
                onClick={() => {
                  document.getElementById("menu").style.right = "-70%";
                }}
                className="fa-solid fa-xmark"
              ></i>
            </div>
            <hr id="hr-ham" />
            <ul>
              <li
                onClick={() => {
                  navigateNav("/home");
                  if (
                    window.location.pathname === "/home" ||
                    window.location.pathname === "/"
                  ) {
                    setPage(1);
                  }
                }}
                style={page === 1 ? { textDecorationColor: "#af2524" } : {}}
              >
                <Link to={"/home"}>{lang ? "Начало" : "Home"}</Link>
              </li>
              <li
                onClick={() => {
                  navigateNav("/services");
                  if (window.location.pathname === "/services") {
                    setPage(2);
                  }
                }}
                style={page === 2 ? { textDecorationColor: "#af2524" } : {}}
              >
                <Link to={"/services"}>{lang ? "Услуги" : "Services"}</Link>
              </li>
              <li
                onClick={() => {
                  navigateNav("/pricing");
                  if (window.location.pathname === "/pricing") {
                    setPage(3);
                  }
                }}
                style={page === 3 ? { textDecorationColor: "#af2524" } : {}}
              >
                <Link to={"/pricing"}>{lang ? "Цени" : "Pricing"}</Link>
              </li>
              <li
                onClick={() => {
                  navigateNav("/contacts");
                  if (window.location.pathname === "/contacts") {
                    setPage(4);
                  }
                }}
                style={page === 4 ? { textDecorationColor: "#af2524" } : {}}
              >
                <Link to={"/contacts"}>{lang ? "Контакти" : "Contacts"}</Link>
              </li>
            </ul>
          </div>
          <i
            onClick={() => {
              document.getElementById("menu").style.right = "-2px";
            }}
            id="ham"
            className="fa-solid fa-bars"
          ></i>
        </nav>
      </div>
    </>
  );
}

export default Nav;
