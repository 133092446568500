import "./footer.css";
import { Link } from "react-router-dom";
function Footer({ lang }) {
  return (
    <div className="footer-basic">
      <footer>
        <div className="social">
          <a href="https://www.instagram.com/optimumstroyltd">
            <i className="icon ion-social-instagram"></i>
          </a>
          <a href="https://www.tiktok.com/@optimumstroyltd">
            <i className="fa-brands fa-tiktok"></i>
          </a>
          <a href="https://www.facebook.com/profile.php?id=61552736887438">
            <i className="icon ion-social-facebook"></i>
          </a>
          <a href="#">
            <i className="fa-brands fa-viber"></i>
          </a>
          <a href="#">
            <i className="fa-solid fa-envelope"></i>
          </a>
          <a href="tel:0877137077">
            <i className="fa-solid fa-square-phone"></i>
          </a>
          <a href="#">
            <i className="fa-solid fa-location-dot"></i>
          </a>
        </div>
        <ul className="list-inline">
          <li className="list-inline-item">
            <Link to={"/home"}>{lang ? "Начало" : "Home"}</Link>
          </li>
          <li className="list-inline-item">
            <Link to={"/services"}>{lang ? "Услуги" : "Services"}</Link>
          </li>
          <li className="list-inline-item">
            <Link to={"/pricing"}>{lang ? "Цени" : "Pricing"}</Link>
          </li>
          <li className="list-inline-item">
            <Link to={"/contacts"}>{lang ? "Контакти" : "Contacts"}</Link>
          </li>
          <li className="list-inline-item">
            <a href="/privacy">Privacy Policy</a>
          </li>
          <li className="list-inline-item">
            <a href="/terms">Terms</a>
          </li>
        </ul>
        <p className="copyright">Optimum Stroy © 2023</p>
      </footer>
    </div>
  );
}

export default Footer;
