import "./form.css";
import axios from "axios";
import loading from "../assets/loading.gif";
function Form() {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const title = document.getElementById("con-title").value;
        const name = `${document.getElementById("con-fname").value} ${
          document.getElementById("con-lname").value
        }`;
        const email = document.getElementById("con-email").value;
        const phone = document.getElementById("con-phone").value;
        const body = document.getElementById("con-body").value;
        const userRequest = { title, name, email, phone, body };
        console.log(userRequest);
        const loading = document.getElementById("loding-mail");
        loading.style.display = "block";
        const loadingImg = document.getElementById("loading-img");
        const loadingSuccess = document.getElementById("loading-text");
        const loadingError = document.getElementById("loading-error");
        axios
          .post("https://api.optimumstroy.com/send", {
            email,
            name,
            phone,
            body,
            title,
          })
          .then((data) => {
            loadingImg.style.display = "none";
            loadingSuccess.style.display = "block";
          })
          .catch(() => {
            loadingImg.style.display = "none";
            loadingError.style.display = "block";
          })
          .finally(() => {
            setTimeout(() => {
              window.location.href = "/home";
            }, 1500);
          });
      }}
      id="contacts-form-wrapper"
    >
      <div id="contacts-form">
        <div id="loding-mail" style={{ display: "none" }}>
          <img id="loading-img" src={loading} alt="loading" />
          <p id="loading-text" style={{ display: "none" }}>
            Запитването беше успешно изпратено!
          </p>
          <p id="loading-error" style={{ display: "none" }}>
            Нещо се обърка, моля опитайте по късно!
          </p>
        </div>
        <div>
          <i className="fa-solid fa-font"></i>
          <input
            id="con-title"
            className="in-con"
            type="text"
            placeholder="Заглавие"
            required
          />
        </div>
        <div id="name">
          <div>
            <i className="fa-solid fa-user"></i>
            <input
              id="con-fname"
              className="in-con"
              type="text"
              placeholder="Име"
              required
            />
          </div>
          <div>
            <i className="fa-solid fa-user"></i>
            <input
              id="con-lname"
              className="in-con"
              type="text"
              placeholder="Фамилия"
              required
            />
          </div>
        </div>
        <div>
          <i className="fa-solid fa-envelope"></i>
          <input
            id="con-email"
            className="in-con"
            type="email"
            placeholder="Имейл"
            required
          />
        </div>
        <div>
          <i className="fa-solid fa-phone"></i>
          <input
            id="con-phone"
            className="in-con"
            type="tel"
            placeholder="Телефон"
            required
          />
        </div>
        <div>
          <i className="fa-solid fa-t"></i>
          <textarea id="con-body" required />
        </div>
        <button type="submit">Изпрати</button>
      </div>
    </form>
  );
}

export default Form;
